import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Project = () => {
    const data = useStaticQuery(graphql`
    query ProjectQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        <div id="projects" class="w-full pt-10">
            <h1 class='text-white text-center text-5xl pb-4 text-bold'>My Projects</h1>

            <div class="px-10 py-4 last-of-type:mb-6">
                <div class=" w-10/12 mx-auto lg:max-w-full lg:flex">
                    <div class="bg-[url('/logo.jpg')] h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-l-xl  text-center overflow-hidden" title="Mountain">
                    </div>
                    <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-secondary rounded-b lg:rounded-b-none lg:rounded-r-xl p-4 flex flex-col justify-between leading-normal">
                        <a class="mb-8">
                            <div class="font-bold text-xl mb-2">Developing Community Based Insurance Scheme to Mitigate Human Wildlife Conflict</div>
                            <p class="text-base"> Team Leader of the research for Developing Community Based Insurance Scheme to Mitigate Human Wildlife Conflict in Gaurishankhar Conservation Area, Nepal.</p>
                        </a>
                        <div class="flex items-center">
                            <div class="text-sm">
                                <p class="">2021 (ongoing)</p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div class="px-10 py-4 last-of-type:mb-6">
                <div class=" w-10/12 mx-auto lg:max-w-full lg:flex">
                    <div class="bg-[url('/logo.jpg')] h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-l-xl  text-center overflow-hidden" title="Mountain">
                    </div>
                    <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-secondary rounded-b lg:rounded-b-none lg:rounded-r-xl p-4 flex flex-col justify-between leading-normal">
                        <a href="https://www.rufford.org/projects/suman-shree-neupane/genetic-diversity-and-conservation-of-himalayan-black-bear-in-nepal/" class="mb-8">
                            <div class="font-bold text-xl mb-2">Genetic Diversity and Conservation of Himalayan Black Bear in Nepal</div>
                            <p class="text-base">Received Rufford Small Grant from Rufford Foundation to carry out research on Genetic Diversity and Conservation of Himalayan Black Bear in Nepal (£6000).</p>
                        </a>
                        <div class="flex items-center">
                            <div class="text-sm">
                                <p class="">2020(Ongoing)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-10 py-4 last-of-type:mb-6">
                <div class=" w-10/12 mx-auto lg:max-w-full lg:flex">
                    <div class="bg-[url('/logo.jpg')] h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-l-xl  text-center overflow-hidden" title="Mountain">
                    </div>
                    <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-secondary rounded-b lg:rounded-b-none lg:rounded-r-xl p-4 flex flex-col justify-between leading-normal">
                        <a href="https://www.researchgate.net/project/Ecotourism-in-Gaurishankar-Conservation-Area-Nepal" class="mb-8">
                            <div class="font-bold text-xl mb-2">Nature Conservation on
                                Economic Valuation of Ecotourism and Conservation in Gaurisankhar Conservation Area</div>
                            <p class="text-base">Received Student Dissertation Grant Program from National Trust for Nature Conservation on Economic Valuation of Ecotourism and Conservation in Gaurisankhar Conservation Area, Nepal (NPR 30,000) from 19th October 2016 to 30th April 2017.</p>
                        </a>
                        <div class="flex items-center">
                            <div class="text-sm">
                                <p class="">2016-17</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Project
