import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Pictures = () => {
    const data = useStaticQuery(graphql`
    query PicturesQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        <div class="py-5">
            <h1 class='text-white text-center text-5xl p-4 text-bold'>Some Pictures</h1>
            <div class="container my-12 mx-auto px-4 md:px-12 w-3/4 md:w-full">
                <div class="flex flex-wrap -mx-1 lg:-mx-4">
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic1.jpg" />
                        </div>
                    </div>
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic2.jpg" />
                        </div>
                    </div>
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic3.jpg" />
                        </div>
                    </div>
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic6.jpg" />
                        </div>
                    </div>
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic5.jpg" />
                        </div>
                    </div>
                    <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <div class="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" class="block h-auto w-full" src="/pic4.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pictures
