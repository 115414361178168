import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const FeaturedNews = () => {
    const data = useStaticQuery(graphql`
    query FeaturedNewsQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        <div id="projects" class="w-full pt-10">
            <h1 class='text-white text-center text-5xl pb-4 text-bold'>Featured News</h1>

            <div class="px-10 py-4 last-of-type:mb-6">
                <div class=" w-10/12 mx-auto lg:max-w-full lg:flex">
                    <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-secondary rounded lg:rounded-xl p-4 flex flex-col justify-between leading-normal">
                        <a class="mb-8" href="https://conservationnation.org/giorgia-auteri/" target="blank">
                            <div class="font-bold text-xl mb-2">Prioritizing Research and Conservation of Endangered Black Bears</div>
                            <p class="text-base"> ...Giorgia's project will focus on the threatened—and under-researched—Himalayan black bears of the mid-to-high hills of Nepal. This species faces several threats, including poaching, climate change, habitat degradation and fragmentation, and conflicts with communities because of the bears' use of agricultural resources... </p>
                        </a>
                        <div class="flex items-center">
                            <div class="text-sm">
                                <p class="">October 20, 2022</p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div class="px-10 py-4 last-of-type:mb-6">
                <div class=" w-10/12 mx-auto lg:max-w-full lg:flex">
                    <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-secondary rounded lg:rounded-xl p-4 flex flex-col justify-between leading-normal">
                        <a class="mb-8" href="https://blogs.missouristate.edu/cnas-newswatch/2023/01/19/a-beacon-for-bats-and-bears/" target="blank">
                            <div class="font-bold text-xl mb-2">A beacon for bats and bears</div>
                            <p class="text-base"> ...Dr. Giorgia Auteri is new to Missouri State University, but not the world of evolutionary biology.

                                For the last six years, she has been aiming to understand the full extent of how mammals adapt to changes in their environment caused by humans.

                                Now an assistant biology professor at MSU, Auteri has achieved much along the way...</p>
                        </a>
                        <div class="flex items-center">
                            <div class="text-sm">
                                <p class="">January 19, 2023</p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >


        </div >
    )
}


export default FeaturedNews