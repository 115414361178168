import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Timeline = () => {
    const data = useStaticQuery(graphql`
    query TimelineQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        <div class="pt-10">
            <h1 class='text-white text-center text-5xl pb-4 text-bold'>My Timeline</h1>
            <div class="timelineforwork">
                <div class="theDiv right">
                    <div class="date">2023</div>
                    <div class="content rounded-xl">
                        <a href="https://biology.missouristate.edu/Graduate/default.htm" target="blank">
                            <h2>Master of Science in Biology</h2>
                            <p>
                                <strong>Missouri State University</strong>
                            </p>
                        </a>
                    </div>
                </div>
                <div class="theDiv left">
                    <div class="date">2019-21</div>
                    <div class="content rounded-xl">
                        <h2>Lecturer</h2>
                        <p>
                            <strong>Amity Global College</strong> <br></br>
                            Forest Law and Policies, and Wildlife Management <br></br>
                            Diploma Students
                        </p>
                    </div>
                </div>
                <div class="theDiv right">
                    <div class="date">2019-21</div>
                    <div class="content rounded-xl">
                        <h2>M.Sc. Wildlife Management and Biodiversity Conservation</h2>
                        <p>
                            <strong>School of Forestry and Natural Resource Management</strong>, Tribhuvan University
                        </p>
                    </div>
                </div>
                <div class="theDiv left">
                    <div class="date">2019</div>
                    <div class="content rounded-xl">
                        <h2>Research Assistant and GIS Analyst</h2>
                        <p>
                            <strong>SMART Pvt. Ltd</strong><br></br>
                            National Level Bear Action Plan Development <br></br>
                        </p>
                    </div>
                </div>
                <div class="theDiv left">
                    <div class="date">2018-19</div>
                    <div class="content rounded-xl">
                        <h2>Research Assistant and GIS Analyst</h2>
                        <p>
                            <strong>SMART Pvt. Ltd</strong> <br></br>
                            Assessment of Blue-bull Population, distribution, behavioral pattern, and human-blue-bull conflict status in Nepal
                        </p>
                    </div>
                </div>
                <div class="theDiv left">
                    <div class="date">2017-18</div>
                    <div class="content rounded-xl">
                        <h2>Program Officer</h2>
                        <p>
                            <strong>Community Forestry Research and Training Center (COMFORTC)</strong>, Sindhupalchowk, Nepal
                        </p>
                    </div>
                </div>
                <div class="theDiv right">
                    <div class="date">2017</div>
                    {/* <i class="icon fa fa-running"></i> */}
                    <div class="content rounded-xl">
                        <h2>B.Sc. Forestry</h2>
                        <p>
                            <strong>Kathmandu Forestry College</strong>, Tribhuvan University
                        </p>
                    </div>
                </div>
                <div class="theDiv right">
                    <div class="date">2012</div>
                    {/* <i class="icon fa fa-cog"></i> */}
                    <div class="content rounded-xl">
                        <h2>+2 Science</h2>
                        <p>
                            <strong>Capital College and Research Centre</strong>, Higher Secondary Education Board
                        </p>
                    </div>
                </div>
                <div class="theDiv right">
                    <div class="date">2010</div>
                    <div class="content rounded-xl">
                        <h2>SLC</h2>
                        <p>
                            <strong>Durga Bhawani Academy</strong>, Government of Nepal
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timeline
