import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Grant = () => {
    const data = useStaticQuery(graphql`
    query GrantQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        <div class="pt-5">
            <h1 class='text-white text-center text-5xl p-4 text-bold'>Grants, Awards and Honors</h1>

            <div class='text-white mb-5 mx-2' id="content">
                <ul class="timeline">
                    <li class="event">
                        <span class="text-xl">2021</span>
                        <p class="mt-3">IDEA WILD Equipment Grant for Research and Conservation of Himalayan Black Bears in Nepal</p>
                        <p>Grant received: $480</p>
                    </li>
                    <li class="event">
                        <span class="text-xl">2020</span>
                        <p class="mt-3">Rufford Small Grant from Rufford Foundation</p>
                        <p>Grant received: £6000</p>
                    </li>
                    <li class="event">
                        <span class="text-xl">2016/17</span>
                        <p class="mt-3">Student Dissertation Grant Program from National Trust for Nature Conservation</p>
                        <p>Grant received: NPR 30,000</p>
                    </li>
                </ul>
            </div>

        </div >
    )
}

export default Grant

// <h1 class='text-white text-center text-5xl pb-4 text-bold'>My Grant</h1>
//             <div class="grant">
//                 <div class="theDiv left">
//                     <div class="date">2019-21</div>
//                     <div class="content rounded-xl">
//                         <h2>Lecturer</h2>
//                         <p>
//                             <strong>Amity Global College</strong> <br></br>
//                             Forest Law and Policies, and Wildlife Management <br></br>
//                             Diploma Students
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2019-21</div>
//                     <div class="content rounded-xl">
//                         <h2>M.Sc. Wildlife Management and Biodiversity Conservation</h2>
//                         <p>
//                             School of Forestry and Natural Resource Management, Tribhuvan University
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2019</div>
//                     <div class="content rounded-xl">
//                         <h2>Research Assistant and GIS Analyst</h2>
//                         <p>
//                             <strong>SMART Pvt. Ltd</strong><br></br>
//                             National Level Bear Action Plan Development <br></br>
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2018-19</div>
//                     <div class="content rounded-xl">
//                         <h2>Research Assistant and GIS Analyst</h2>
//                         <p>
//                             <strong>SMART Pvt. Ltd</strong> <br></br>
//                             Assessment of Blue-bull Population, distribution, behavioral pattern, and human-blue-bull conflict status in Nepal
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2017/18</div>
//                     <div class="content rounded-xl">
//                         <h2>Program Officer</h2>
//                         <p>
//                             <strong>Community Forestry Research and Training Center (COMFORTC)</strong>, Sindhupalchowk, Nepal
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2017</div>
//                     {/* <i class="icon fa fa-running"></i> */}
//                     <div class="content rounded-xl">
//                         <h2>B.Sc. Forestry</h2>
//                         <p>
//                             Kathmandu Forestry College, Tribhuvan University
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2012</div>
//                     {/* <i class="icon fa fa-cog"></i> */}
//                     <div class="content rounded-xl">
//                         <h2>+2 Science</h2>
//                         <p>
//                             Capital College and Research Centre, Higher Secondary Education Board
//                         </p>
//                     </div>
//                 </div>
//                 <div class="theDiv left">
//                     <div class="date">2010</div>
//                     <div class="content rounded-xl">
//                         <h2>SLC</h2>
//                         <p>
//                             Durga Bhawani Academy, Government of Nepal
//                         </p>
//                     </div>
//                 </div>
//             </div>