import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Intro = () => {
    const data = useStaticQuery(graphql`
    query IntroQuery {
    site {
        siteMetadata {
        author {
            name
            summary
        }
        social {
            twitter
        }
        }
    }
    }
`)

    return (
        // <div class='w-full flex flex-col md:flex-row'>
        //     <div class='text w-full md:w-1-2'>
        //         <h1>Hi I am Suman Shree Neupane</h1>
        //         <p>Lore </p>
        //     </div>
        //     <div class='photo w-full md:w-1-2'>
        //         <img src='/suman-photo.jpg' class='theHomePhoto'></img>
        //     </div>
        // </div>
        <div>
            <section class="mt-5">
                <div class="container flex flex-col-reverse px-6 pt-0   mx-auto space-y-2  lg:flex-row lg:items-center">
                    <div class="w-full lg:w-1/2">
                        <div class="lg:max-w-lg">
                            <h1 class="text-3xl my-5 font-bold slide-up tracking-wide text-gray-200 lg:text-5xl">
                                Hi, I am Suman Shree Neupane!
                            </h1>

                            <div class="mt-3">
                                <p class="items-center md:mr-5 text-gray-200 ">
                                    Good to see you here! <br></br>
                                    I am a biodiversity researcher, based on Nepal.
                                    My areas of interest cover Quantitative Ecology, Wildlife Ecology Conservation Biology and Natural Resource Economics. I have been active in biodiversity research and conservation scenario for a decade now. I love traveling and strolling in the mountains while contemplating the beauty that this small country beholds. <br></br>
                                    Technically, I am proficient in GIS and Remote Sensing. Currently, there are two <a class="text-[#E7F6F2] font-bold" href="#projects">research projects</a> that I am leading. <br></br>
                                    I believe our researches are a collective effort to making this planet a better place to live by making an impact on various domains. Over the years, I have learned to make the most of the resources I have - after all, that is what I aim to teach &#59;&#41;<br></br>
                                    <br />
                                    <a href="https://www.giorgiaauteri.com/people" target="blank" class="underline">
                                        I am currently under the wing of Professor Giorgia G. Auteri.
                                    </a>
                                    <br />
                                    <a href="https://woodwellrisk.github.io/Nepal_Workshops/index.html#goals-of-training" target="blank">
                                        <span class="italic text-white font-medium rounded-lg text-xl lg:text-xl">Click to know more about a recent event I am a part of! </span>
                                    </a>
                                    

                                </p>
                                <a href="/contact">
                                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-3 mr-2 mb-2 mt-5 text-lg  focus:outline-none ">Contact Me</button>
                                </a>

                            </div>
                        </div>

                    </div>

                    <div class="items-center justify-center h-full lg:w-1/2">
                        <img class="object-cover h-full mx-auto theHomePhoto xl:mt-10" src="/suman-photo.jpg" alt="glasses photo"></img>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Intro
