import React from "react"

function Publications() {
    return (
        <div class="pt-5">
            <h1 class='text-white text-center text-5xl p-4 text-bold'>Publications</h1>

            <div class='text-white mb-5 mx-2' id="content">
                <ul class="timeline">
                    <li class="event">
                        <a href="http://dx.doi.org/10.1080/14724049.2022.2052080" target="blank" class="text-xl">Motivation and service quality
                            relationship with visitor satisfaction and loyalty intentions in a mountainous protected
                            area</a>
                        <p class="mt-3 text-sm font-weight-400 text-gray-100">Paudyal, R., Baniya, R., Thapa, B., Neupane, S. S., &amp; Birendra, K. C. (2022). Motivation and service quality
                            relationship with visitor satisfaction and loyalty intentions in a mountainous protected
                            area. Journal of Ecotourism, 1-18.
                            <a target="blank" href="http://dx.doi.org/10.1080/14724049.2022.2052080">http://doi.org/10.1080/14724049.2022.2052080</a>
                        </p>
                    </li>
                    <li class="event">
                        <a href="https://doi.org/10.17161/eurojecol.v7i1.15005" target="blank" class="text-xl">Regeneration Status
                            and Carbon Accumulation Potential in Community Managed Sal (Shorea Robusta) Forests of
                            Far-Western Terai Region, Nepal</a>
                        <p class="mt-3 text-sm font-weight-400 text-gray-100">Rajeev Joshi, Mandip Pangeni, Suman Shree Neupane, Nagendra Prasad Yadav (2021) Regeneration Status
                            and Carbon Accumulation Potential in Community Managed Sal (Shorea Robusta) Forests of
                            Far-Western Terai Region, Nepal. European Journal of Ecology.
                            <a href="https://doi.org/10.17161/eurojecol.v7i1.15005" target="blank">https://doi.org/10.17161/eurojecol.v7i1.15005</a>.
                        </p>
                    </li>
                    <li class="event">
                        <a href="https://doi.org/10.1007/s11629-020-6179-z" class="text-xl" target="blank">Motive-based segmentation of international tourists at
                            Gaurishankar Conservation Area, Nepal.</a>
                        <p class="mt-3 text-sm font-weight-400 text-gray-100">Baniya R Thapa B Paudyal R, Neupane SS (2021) Motive-based segmentation of international tourists at
                            Gaurishankar Conservation Area, Nepal. Journal of Mountain Science 18(1).
                            <a href="https://doi.org/10.1007/s11629-020-6179-z" target="blank">https://doi.org/10.1007/s11629-020-6179-z</a>.
                        </p>
                    </li>
                    <li class="event">
                        <a href="https://doi.org/10.3390/su10103488" target="blank" class="text-xl">Factors associated with
                            conservation participation by local communities in Gaurisankhar Conservation Area Project,
                            Nepal, Sustainability.</a>
                        <p class="mt-3 text-sm font-weight-400 text-gray-100">Ramesh Paudyal, Brijesh Thapa, Suman Shree Neupane and Birendra K C (2018): Factors associated with
                            conservation participation by local communities in Gaurisankhar Conservation Area Project,
                            Nepal, Sustainability, 2018, 3488, <a href="https://doi.org/10.3390/su10103488" target="blank">DOI: /10.3390/su10103488.</a>
                        </p>
                    </li>
                    <li class="event">
                        <a href="https:/doi.org/10.1080/10941665.2018.1467938" class="text-xl" target="blank">Residents' perspectives of a newly developed
                            ecotourism project: an assessment of effectiveness through the lens of an importance-
                            performance analysis.</a>
                        <p class="mt-3 text-sm font-weight-400 text-gray-100">Birendra KC, Ramesh Paudyal &amp; Suman Shree Neupane (2018): Residents' perspectives of a newly developed
                            ecotourism project: an assessment of effectiveness through the lens of an importance-
                            performance analysis, Asia Pacific Journal of Tourism Research, <a href="https:/doi.org/10.1080/10941665.2018.1467938" target="blank">DOI: 10.1080/10941665.2018.1467938.</a>
                        </p>
                    </li>
                </ul>
            </div>

        </div >
    )
}

export default Publications